import request from '@/utils/request'
const baseURL = process.env.VUE_APP_BASE_API

export function fetchTransportDocList(params) {
    return request({
        url: baseURL + '/transportDoc/list',
        method: 'GET',
        params: params
    })
}

export function fetchTransportDocDetail(params) {
    return request({
        url: baseURL + '/transportDoc/detail',
        method: 'GET',
        params: params
    })
}

export function fetchTransportDocSave(data) {
    return request({
        url: baseURL + '/transportDoc/save',
        method: 'POST',
        data
    })
}